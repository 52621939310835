import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import picture from '../../assets/Marguerite.jpg';

const About = ({ history }) => {

  return (
      <Container align="center" maxWidth="sm">
        <Typography variant="h4" gutterBottom>About Us</Typography>
        <Typography variant="h6" align="left" gutterBottom>
        I wanted to take a moment and introduce myself. 
        </Typography>
        <img src={picture} alt=""/>
        <Typography variant="body1" align="left" gutterBottom>
        I started this business in 2014 as a way to support my rescue endeavors in Qatar. 
        From a very young age I have always loved animals and when I went to Qatar with my husband, 
        I saw that so many animals were living horrible lives on the street and I wanted to help. 
        I noticed that I could not find any martingale collars in the country thus Collars for Kings was created because of this void. 
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
        Over the years my husband and I have fostered over 60 dogs, 
        have taken nearly 20 dogs to North America to their forever homes. 
        We have fed street dogs, spayed and neutered them, 
        we have paid for dog crates so dogs could travel out of Qatar, 
        and we have paid for surgeries for dogs with broken bones and 
        we managed to do all of this because people supported my little business. 
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
        We returned to Canada in 2023 and we started the business Canada, and we Fostered our last dog
        in 2024 who we've adopped.
        </Typography>    
        <Typography variant="body1" align="left" gutterBottom>
        I look forward to the future.
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
         
        Marguerite & Fernando
        </Typography>
        <div>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => history.push('/')}
            >
            Continue Shopping
          </Button>
        </div>
      </Container>
  );
}

export default withRouter(About);